<script setup lang="ts">
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()
</script>

<template>
  <div class="avatar placeholder">
    <div class="bg-white text-black rounded-full w-9">
      <span class="font-light text-xl tracking-wide">{{
        userStore.initials
      }}</span>
    </div>
  </div>
</template>
