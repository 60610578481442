<template>
  <div class="footer mt-auto py-5">
    <div class="container place-items-center">
      <div class="flex items-center"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.footer {
  a {
    @apply text-base;

    color: #6b6b6b;

    &.router-link-exact-active {
      @apply text-primary font-bold;
    }
  }
}
</style>
